<template>
  <div class="app-container">
    <el-card class="form-container" shadow="never">
      <el-form>
        <el-form-item label="Displayable Code">
          <el-input v-model="promoParam.displayableCode"></el-input>
        </el-form-item>
        <el-form-item label="Amount">
          <el-input v-model="addAmount"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit">Submit</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-dialog title="Confirm Operation" :visible.sync="confirmVisible" v-if="consumer">
      <p>
        Nickname:{{consumer.nickname}}
      </p>
      <p>
        Email: {{consumer.email}}
      </p>
      <p>
        Displayable Code: {{consumer.displayableCode}}
      </p>
      <p>
        Before: <b style="color:#FF0000">{{'$'+beforeAmount}}</b>
      </p>
      <p >
        After: <b style="color:#059504">{{'$'+afterAmount}}</b>
      </p>
      <p style="align:center">
        <el-button @click="confirmVisible=false;beforeAmount=null;afterAmount=null;">Cancel</el-button>
        <el-button type="primary" @click="confirmSubmit">Confirm</el-button>
      </p>
    </el-dialog>
  </div>
</template>
<script>
import { getCurrentAmount, setAmount } from '@/api/promo';

export default {
  data() {
    return {
      promoParam: {
        displayableCode: null,
        amount: null,
      },
      addAmount: null,
      beforeAmount: null,
      afterAmount: null,
      confirmVisible: false,
      consumer: null,
    };
  },
  methods: {
    submit() {
      let addAmountFloat = parseFloat(this.addAmount);
      if (addAmountFloat == null) {
        this.$message({
          message: '请检查amount格式',
          type: 'warning',
          duration: 1000,
        });
        return;
      }
      addAmountFloat = parseFloat(addAmountFloat.toFixed(2));
      getCurrentAmount({ displayableCode: this.promoParam.displayableCode }).then((response) => {
        this.beforeAmount = response.data.amount.amount;
        this.afterAmount = this.beforeAmount + addAmountFloat;
        this.confirmVisible = true;
        this.consumer = response.data;
      });
    },
    confirmSubmit() {
      const param = { ...this.promoParam };
      param.amount = this.afterAmount;
      setAmount(param).then(() => {
        this.$message({
          message: 'Success',
          type: 'success',
          duration: 1000,
        });
        this.beforeAmount = null;
        this.afterAmount = null;
        this.confirmVisible = false;
      });
    },
  },
};
</script>
